@import '~bootstrap/scss/bootstrap';

// Variables
$primary-color: rgb(246, 251, 244);
$secondary-color: rgb(242, 249, 241);
$text-color: #555;
$accent-color: #3ab73e;
$shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

// Mixins
@mixin fixed-full-width {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

// Base styles
body {
  font-family: "Open Sans", sans-serif;
  background-color: $primary-color;
  color: $text-color;
  font-size: 0.875rem;
  line-height: 1.6;
  margin: 0;
}

// Layout components
.map {
  margin: 0;
  padding: 0;
  position: relative;
  min-height: 100vh;

  .maptitle {
    position: absolute;
    bottom: 0;
    background-color: $secondary-color;
    color: #4d4d4d;
    opacity: 0.8;
    width: 100%;
    padding: 10px;
    font-size: 1.5rem;
    z-index: 999;
    --bs-gutter-x: 2.5rem;
    margin: 0;
  }
}
.map-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px;
  text-align: center;
  font-size: 1.2em;
  transition: opacity 0.5s ease-in-out;
}
.header {
  @include fixed-full-width;
  background-color: #efefef;
  box-shadow: $shadow;
}

.nb {
  @include fixed-full-width;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
}

.navbar-brand {
  margin-left: 5px;
  margin-bottom: 3px;
  height: 40px;
}

// Sections
section {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
}

.impressum {
  background-color: azure;
}

.main-footer {
  color: rgb(39, 38, 38);
  text-align: center;
}

// Treatments
.treatments {
  .icon {
    font-size: 1.5rem;
    color: $accent-color;
  }

  .icon-box {
    width: 100%;
    padding: 20px;
    margin-bottom: 20px;
    background: #fff;
    text-align: center;
    box-shadow: $shadow;
  }

  @media (min-width: 768px) {
    .row {
      display: flex;
      flex-wrap: wrap;
    }

    [class^="col-"] {
      display: flex;
    }
  }
}

// Icons
.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor;

  &:not(:root) {
    overflow: hidden;
  }
}

// Forms
input,
textarea,
button,
select {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  background-color: transparent;
  border: 1px solid #aaa;
}

input,
textarea {
  width: 200px;
}

label {
  width: 200px;
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-control {
  display: inline-block;
  width: inherit;
}

textarea#background {
  height: 100px;
}

// Modal
.modal-content {
  opacity: 0.97;
  background-color: #f9f7f7;
}

.modal-title {
  margin: 0;
  line-height: 1.428571429;
}

// Typography
h4,
.h4 {
  font-size: 1.143rem;
}

// Utility classes
.loading-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.error-message {
  color: red;
}
 